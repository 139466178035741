<template>
    <module-create-layout>
        <moduleForm :dataContainer="dataContainer" :edit-mode="false" :formHeader="true" :formTitle="'Add ' + module.info.caption"></moduleForm>
    </module-create-layout>
</template>

<script>
    import dataContainer from 'devegram-vue-data-collecter-eui/src/mixins/dataContainer';
    import module from './../index.js'

    export default {
        mixins: [dataContainer],
        components: {
            moduleForm: () => import('./../form/View'),
            moduleCreateLayout: () => import('@/modules-config/layouts/create/View.vue')
        },
        data() {
            return {
                dataContainer: this,
                module: module
            }
        }
    }
</script>
